// Generated by Framer (2f783ed)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["B8TJjtqWD", "FGYnWQFNB"];

const serializationHash = "framer-WG6wL"

const variantClassNames = {B8TJjtqWD: "framer-v-mt62ke", FGYnWQFNB: "framer-v-14p3qu8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "B8TJjtqWD", "Variant 2": "FGYnWQFNB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "B8TJjtqWD"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "B8TJjtqWD", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-mt62ke", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"B8TJjtqWD"} ref={refBinding} style={{backgroundColor: "var(--token-10f2fb8a-d082-42f4-bd26-c9c49d6724c4, rgb(250, 198, 31))", borderBottomLeftRadius: 150, borderBottomRightRadius: 150, borderTopLeftRadius: 150, borderTopRightRadius: 150, ...style}} {...addPropertyOverrides({FGYnWQFNB: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FCQyBHaW50byBOb3JtYWwgUmVndWxhcg==", "--framer-font-family": "\"ABC Ginto Normal Regular\", \"ABC Ginto Normal Regular Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-letter-spacing": "0.32em", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)))", "--framer-text-transform": "uppercase"}}>Play video</motion.p></React.Fragment>} className={"framer-bj79t0"} fonts={["CUSTOM;ABC Ginto Normal Regular"]} layoutDependency={layoutDependency} layoutId={"NTULGNpQb"} style={{"--extracted-r6o4lv": "var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WG6wL.framer-evv1cy, .framer-WG6wL .framer-evv1cy { display: block; }", ".framer-WG6wL.framer-mt62ke { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 20px 8px 20px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-WG6wL .framer-bj79t0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WG6wL.framer-mt62ke { gap: 0px; } .framer-WG6wL.framer-mt62ke > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-WG6wL.framer-mt62ke > :first-child { margin-left: 0px; } .framer-WG6wL.framer-mt62ke > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 146
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"FGYnWQFNB":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrTTwXqxk9: React.ComponentType<Props> = withCSS(Component, css, "framer-WG6wL") as typeof Component;
export default FramerrTTwXqxk9;

FramerrTTwXqxk9.displayName = "cursor-play";

FramerrTTwXqxk9.defaultProps = {height: 40, width: 146};

addPropertyControls(FramerrTTwXqxk9, {variant: {options: ["B8TJjtqWD", "FGYnWQFNB"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerrTTwXqxk9, [{explicitInter: true, fonts: [{family: "ABC Ginto Normal Regular", source: "custom", url: "https://framerusercontent.com/assets/Zo2iDvx3OzYE7u7BjTe2l2dHYI8.woff2"}]}], {supportsExplicitInterCodegen: true})